import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from './api.service';
import { map, Observable } from 'rxjs';
import { ECargo } from '../enums/e-cargo.enum';
import { TurmaService } from './turma.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _isLoggedIn: boolean = false;

  public get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  public get userId(): string | undefined {
    return this.currentUser.id;
  }

  currentUser: {
    id?: string;
    name?: string;
    email?: string;
    roles?: ECargo[];
  } = {};

  hasRole(role: ECargo): boolean {
    if (!this.currentUser.roles) return false;

    return (
      this.currentUser.roles.length == 1 &&
      this.currentUser.roles.includes(role)
    );
  }

  constructor(
    private cookieService: CookieService,
    private router: Router,
    protected apiService: ApiService,
    private _snackBar: MatSnackBar
  ) {}

  private turmaService = inject(TurmaService);

  checkLogin(): Observable<any> {
    return this.apiService.get<any>('auth/check_login').pipe(
      map((response) => {
        this._isLoggedIn = true;
        this.currentUser = {
          ...response,
        };
        return response;
      })
    );
  }

  login(username?: string, password?: string) {
    this.apiService
      .post('auth/login', {
        username,
        password,
      })
      .subscribe({
        next: ({ access_token }) => {
          this.setToken(access_token);
          this.router.navigate(['']);
        },
        error: ({ error }) => {
          this._snackBar.open(error.message, 'ok', {
            duration: 5000,
          });
        },
      });
  }

  setToken(token: string) {
    this.cookieService.set('access_token', token, undefined, '/');
  }

  logout() {
    while (this.cookieService.get('access_token')) {
      this.cookieService.deleteAll('/');
    }

    this.turmaService.turmas.data = [];

    this._isLoggedIn = false;
    this.router.navigate(['/login']);
  }
}
