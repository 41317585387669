import { NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, inject, input, ViewChild, viewChild } from '@angular/core';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterModule } from '@angular/router';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { LogomarkComponent } from '../logomark/logomark.component';
import { MatListModule } from '@angular/material/list';
import { IMenuItem } from '../../models/menu-item.model';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ColaboradorDialogComponent } from '../colaborador-dialog/colaborador-dialog.component';
import { ECargo } from '../../enums/e-cargo.enum';
import { TurmaDialogComponent } from '../turma-dialog/turma-dialog.component';

@Component({
  selector: 'app-base-layout',
  standalone: true,
  imports: [
    MatSidenavModule,
    NgClass,
    RouterModule,
    ToolbarComponent,
    LogomarkComponent,
    MatListModule,
    MatIconModule
  ],
  templateUrl: './base-layout.component.html',
  styleUrl: './base-layout.component.scss',
})
export class BaseLayoutComponent {
  mobileQuery: MediaQueryList;
  private authService = inject(AuthService)

  fillerNav: IMenuItem[] = [
    {
      label: 'Turmas',
      path: '/',
      match: ['/turma'],
      icon: 'group',
      children:[
        {
          label: 'Nova turma',
          icon: 'add',
          action:() => {
            this.dialog.open(TurmaDialogComponent);
          },
          hide: () => {

            const roles = this.authService.currentUser.roles;

            if (roles === undefined)
              return false;

            return roles.length == 1 && roles.includes(ECargo.Professor)
          },
        }
      ]
    },
    // {
    //   label: 'Alunos',
    //   path: 'alunos',
    //   icon: 'school',
    // },
    {
      label: 'Provas',
      path: '/provas',
      icon: 'description',
    },
    // {
    //   label: 'Perfil',
    //   path: 'profile',
    //   icon: 'person',
    // },
    {
      label: 'Colaboradores',
      path: '/colaboradores',
      icon: 'work',
      hide: ()=> {
        return !this.authService.currentUser.roles?.includes(ECargo.Adm)
      },
      children:[
        {
          label: 'Novo',
          icon: 'add',
          action:()=>{
            this.dialog.open(ColaboradorDialogComponent);
          }
        }
      ]
    },
    // {
    //   label: 'Relatórios',
    //   icon: 'query_stats'
    // },
    {
      label: 'Sair',
      icon: 'logout',
      action: ()=>{
        this.authService.logout();
      }
    },
  ];

  readonly dialog = inject(MatDialog);

  private _mobileQueryListener: () => void;

  currentUrl: string = '';

  @ViewChild('snav') snav!: MatSidenav;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.router.events.subscribe((url: any) => {
      this.currentUrl = this.router.url;
    });
  }

  isActive(nav: IMenuItem): boolean {
    return (
      this.currentUrl === nav.path ||
      (nav.match !== undefined && nav.match.indexOf(this.currentUrl) > -1)
    );
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onMenuClick(nav:IMenuItem){

    if(nav.action)
      nav.action(); 
    
    if(this.mobileQuery.matches)
      this.snav.close()
  }
}
