@if(stages.length ==0) {
<div class="h-full flex flex-col gap-5 justify-center items-center">
    <app-logomark [theme]="'dark'" class="md:hidden"></app-logomark>
    <app-loading></app-loading>
</div>
}
@else {
<table mat-table [dataSource]="stages" class="table">

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
            <div class="text-nowrap">
                <mat-icon class="mat-18 align-bottom mr-1">menu_book</mat-icon> Estágio
            </div>
        </th>
        <td mat-cell *matCellDef="let stage">
            {{stage.stage_name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="weight">
        <th mat-header-cell *matHeaderCellDef> 
            <div class="text-center">
                Provas realizadas
            </div>
        </th>
        <td mat-cell *matCellDef="let stage">
            <button mat-button (click)="seeExamsResults(stage.stage_id, stage.stage_name)" 
                [disabled]="stage.exam_count == 0">
                <mat-icon class="mat-18 align-middle mr-1">search</mat-icon>
                {{stage.exam_count}}
            </button>
         </td>
    </ng-container>

    <ng-container matColumnDef="symbol">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let stage">
            <button mat-button (click)="scheduleExam(stage.stage_id, stage.stage_name)" class="float-right">
                <mat-icon>calendar_month</mat-icon>
                Agendar
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
}