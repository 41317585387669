import { Component, inject } from '@angular/core';
import { DiarioComponent } from '../diario/diario.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDiario } from '../../models/diario.model';

@Component({
  selector: 'app-timesheet-dialog',
  standalone: true,
  imports: [DiarioComponent],
  templateUrl: './timesheet-dialog.component.html',
  styleUrl: './timesheet-dialog.component.scss'
})
export class TimesheetDialogComponent {

  readonly diaryList? = inject<IDiario[]>(MAT_DIALOG_DATA);


}
