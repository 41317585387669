import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IDiario } from '../models/diario.model';
import { finalize } from 'rxjs';

type diaryReturn = {
  days: IDiario[];
  classroomProgress: number;
};

@Injectable({
  providedIn: 'root',
})
export class DiaryService {
  private apiService = inject(ApiService);

  diaries: IDiario[] = [];
  classroomProgress?: number;

  _isLoading: boolean = false;

  get isLoading() {
    return this._isLoading;
  }

  load(classroom_id: string) {
    setTimeout(() => {
      this._isLoading = true;
    }, 0);
    
    this.apiService
      .getData<diaryReturn>('diario/', {
        classroom_id,
      })
      .pipe(
        finalize(() => {
          this._isLoading = false;
        })
      )
      .subscribe((diario) => {
        this.diaries = diario.days;
        this.classroomProgress = diario.classroomProgress;
      });
  }

  put(body: any) {
    this._isLoading = true;
    return this.apiService.post('/diario/', body).pipe(
      finalize(() => {
        this._isLoading = false;
      })
    );
  }
}
