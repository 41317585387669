import { Component, inject, input, output } from '@angular/core';
import {
  ETurmaStatus,
  ETurmaStatusColor,
  getTurmaStatusColor,
} from '../../enums/e-turma-status.enum';
import { ApiService } from '../../services/api.service';
import { IDiario } from '../../models/diario.model';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, NgClass } from '@angular/common';
import { ChipComponent } from '../chip/chip.component';
import { enumToString } from '../../utils/enum-to-string.util';
import { getDayOfWeek } from '../../utils/get-day-of-week.util';
import { EClassType } from '../../enums/e-class-type.enum';
import {
  EClassStatus,
  EClassStatusColor,
} from '../../enums/e-class-status.enum';
import { getEnumValue } from '../../utils/get-enum-value.util';
import { LoadingComponent } from '../loading/loading.component';
import { MatDialog } from '@angular/material/dialog';
import { DiaryDialogComponent } from '../diary-dialog/diary-dialog.component';
import { DiaryService } from '../../services/diary.service';
import { MatButtonModule } from '@angular/material/button';


@Component({
  selector: 'app-diario',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    ChipComponent,
    NgClass,
    LoadingComponent,
    MatButtonModule
  ],
  templateUrl: './diario.component.html',
  styleUrl: './diario.component.scss',
})
export class DiarioComponent {
  protected enumToString = enumToString;
  protected eTurmaStatus = ETurmaStatus;
  protected eClassType = EClassType;
  protected eClassStatus = EClassStatus;
  protected eClassStatusColor = EClassStatusColor;
  protected eTurmaStatusColor = ETurmaStatusColor;

  protected getTurmaStatusColor = getTurmaStatusColor;
  protected getDayOfWeek = getDayOfWeek;
  protected getEnumValue = getEnumValue;

  dataSource: IDiario[] = [];
  displayedColumns: string[] = [
    'day',
    'paragraph',
    'lastWord',
    'reading',
    'dictation',
    'teacher',
    // 'status',
  ];

  protected diaryService = inject(DiaryService);
  readonly dialog = inject(MatDialog);

  classroomId = input<string>();
  diaryList = input<IDiario[]>();

  stageId = input<number>();

  ngOnInit(): void {
    const classroom_id = this.classroomId();
    const diaryList = this.diaryList();

    if (typeof classroom_id == 'string')
      this.diaryService.load(classroom_id)

    else if (diaryList) this.dataSource = diaryList;
  }

  isPastDay(day: IDiario): boolean {
    const todayIndex: number = this.dataSource.findIndex((d) => d.isToday);
    const index: number = this.dataSource.findIndex((d) => d.date == day.date);
    return index < todayIndex;
  }

  openDialog(diario: IDiario) {

    this.dialog.open(DiaryDialogComponent, {
      data: {
        stageId: this.stageId(),
        classroomId: this.classroomId(),
        date: diario.date,
        isToday: diario.isToday,
        paragraph: diario.diary?.paragraph,
        dictation: diario.diary?.dictation,
        reading: diario.diary?.reading,
        lastWord: diario.diary?.lastWord
      },
    });
  }
}
