@if(shape() === 'circle'){
<svg class="container" viewBox="0 0 40 40" height="40" width="40">
  <circle class="track" cx="20" cy="20" r="17.5" pathlength="100" stroke-width="5px" fill="none" />
  <circle class="car" cx="20" cy="20" r="17.5" pathlength="100" stroke-width="5px" fill="none" />
</svg>
<style>
  .container {
    --uib-size: 40px;
    --uib-color: black;
    --uib-speed: .8s;
    --uib-bg-opacity: 0.1;
    height: var(--uib-size);
    width: var(--uib-size);
    transform-origin: center;
    animation: rotate var(--uib-speed) linear infinite;
    will-change: transform;
    overflow: visible;
  }

  .car {
    fill: none;
    stroke: var(--uib-color);
    stroke-dasharray: 25, 75;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    transition: stroke 0.5s ease;
  }

  .track {
    fill: none;
    stroke: var(--uib-color);
    opacity: var(--uib-bg-opacity);
    transition: stroke 0.5s ease;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
</style>
}
@else if(shape() === 'mirage'){

<div class="mirage">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
</div>
<svg width="0" height="0" class="svg">
  <defs>
    <filter id="uib-jelly-ooze">
      <feGaussianBlur
        in="SourceGraphic"
        stdDeviation="3"
        result="blur"
      />
      <feColorMatrix
        in="blur"
        mode="matrix"
        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
        result="ooze"
      />
      <feBlend in="SourceGraphic" in2="ooze" />
    </filter>
  </defs>
</svg>

<style>
  .mirage {
    --uib-size: 60px;
    --uib-color: black;
    --uib-speed: 2.6s;
    --uib-dot-size: calc(var(--uib-size) * 0.23);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: var(--uib-size);
    height: var(--uib-dot-size);
    filter: url('#uib-jelly-ooze');
  }

  .dot {
    position: absolute;
    top: calc(50% - var(--uib-dot-size) / 2);
    left: calc(0px - var(--uib-dot-size) / 2);
    display: block;
    height: var(--uib-dot-size);
    width: var(--uib-dot-size);
    border-radius: 50%;
    background-color: var(--uib-color);
    animation: stream var(--uib-speed) linear infinite both;
    transition: background-color 0.3s ease;
  }

  .dot:nth-child(2) {
    animation-delay: calc(var(--uib-speed) * -0.2);
  }

  .dot:nth-child(3) {
    animation-delay: calc(var(--uib-speed) * -0.4);
  }

  .dot:nth-child(4) {
    animation-delay: calc(var(--uib-speed) * -0.6);
  }

  .dot:nth-child(5) {
    animation-delay: calc(var(--uib-speed) * -0.8);
  }

  @keyframes stream {
    0%,
    100% {
      transform: translateX(0) scale(0);
    }

    50% {
      transform: translateX(calc(var(--uib-size) * 0.5)) scale(1);
    }

    99.999% {
      transform: translateX(calc(var(--uib-size))) scale(0);
    }
  }
</style>
}
@else {
  <div class="line-wobble"></div>
  <style>
    .line-wobble {
      --uib-size: 80px;
      --uib-color: black;
      --uib-speed: 1.75s;
      --uib-stroke: 5px;
      --uib-bg-opacity: .1;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--uib-stroke);
      width: var(--uib-size);
      border-radius: calc(var(--uib-stroke) / 2);
      overflow: hidden;
      transform: translate3d(0, 0, 0);
    }
  
    .line-wobble::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: var(--uib-color);
      opacity: var(--uib-bg-opacity);
      transition: background-color 0.3s ease;
    }
  
    .line-wobble::after {
      content: '';
      height: 100%;
      width: 100%;
      border-radius: calc(var(--uib-stroke) / 2);
      animation: wobble var(--uib-speed) ease-in-out infinite;
      transform: translateX(-95%);
      background-color: var(--uib-color);
      transition: background-color 0.3s ease;
    }
  
    @keyframes wobble {
  
      0%,
      100% {
        transform: translateX(-95%);
      }
  
      50% {
        transform: translateX(95%);
      }
    }
  </style>
  }
  
