import { Component } from '@angular/core';

@Component({
  selector: 'app-live-board',
  standalone: true,
  imports: [],
  templateUrl: './live-board.component.html',
  styleUrl: './live-board.component.scss'
})
export class LiveBoardComponent {

}
