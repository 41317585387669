@if(colaborador){
<h2 mat-dialog-title>Editar Colaborador</h2>
} @else {
<h2 mat-dialog-title>Novo Colaborador</h2>
}
<mat-dialog-content>
  <form class="flex flex-col" [formGroup]="form">
    <mat-form-field>
      <mat-label>Nome e sobrenome</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Unidade</mat-label>
      <mat-select formControlName="unit">
          @for (unidade of unidades; track unidade) {
          <mat-option [value]="unidade.id">{{unidade.name}}</mat-option>
          }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Cargos</mat-label>
      <mat-select formControlName="roles" multiple>
        @for (role of rolesList; track role) {
        <mat-option [value]="role.id">{{role.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>

  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancelar</button>
  <button mat-flat-button (click)="onSubmit()" [disabled]="form.invalid || apiService.isLoading">
    {{colaborador ? 'Salvar': 'Adicionar'}}
  </button>
</mat-dialog-actions>