import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { ExamsService } from '../../services/exams.service';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';

export class ExamsReportModel {
  exam_id!: string;
  student_id!: string;
  full_name!: string;
  end_time!: string;
  score!: string;
}
@Component({
  selector: 'app-exam-results-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    RouterModule
  ],
  templateUrl: './exam-results-dialog.component.html',
  styleUrl: './exam-results-dialog.component.scss',
})
export class ExamResultsDialogComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<ExamResultsDialogComponent>);
  readonly data = inject<{ stageId: number; stageName: string }>(
    MAT_DIALOG_DATA
  );

  displayedColumns = ['student', 'score', 'date'];

  showModal: boolean = false;
  examsReport: ExamsReportModel[] = [];

  constructor(protected api: ExamsService) {}

  closeModal() {
    this.showModal = false;
  }

  getReport() {
    this.api
      .get('exams', {
        attribute_behaviour: 'get_finished_exams',
        stage_id: this.data.stageId,
      })
      .toPromise()
      .then((response) => {
        this.examsReport = response.data;
        this.showModal = true;
      });
  }

  ngOnInit(): void {
    this.getReport();
  }
}
