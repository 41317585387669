export function getDayOfWeek(dateStr: string, full: boolean = false): string {
  const date = new Date(dateStr);

  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  let dayOfWeek = daysOfWeek[date.getUTCDay()];

  if (full === false)
    dayOfWeek = dayOfWeek.slice(0,3).toUpperCase();

  const dayOfMonth = date.getUTCDate();
  const suffix = getDaySuffix(dayOfMonth);

  return `${dayOfWeek} ${dayOfMonth}${suffix}`;
}

function getDaySuffix(day: number): string {
  if (day >= 11 && day <= 13) {
      return 'th';
  }
  switch (day % 10) {
      case 1:
          return 'st';
      case 2:
          return 'nd';
      case 3:
          return 'rd';
      default:
          return 'th';
  }
}
