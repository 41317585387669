import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-statistic',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule
  ],
  templateUrl: './statistic.component.html',
  styleUrl: './statistic.component.scss'
})
export class StatisticComponent {

  @Input() label: string = "";
  @Input() icon: string = "";
  @Input() color: string = "primary";

}
