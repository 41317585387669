import { Component, inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { ApiService } from '../../services/api.service';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { getDayOfWeek } from '../../utils/get-day-of-week.util';
import { AuthService } from '../../services/auth.service';
import { ECargo } from '../../enums/e-cargo.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DiaryService } from '../../services/diary.service';
import { IAluno } from '../../models/aluno.model';
import { TurmaService } from '../../services/turma.service';

type option = { id: number; name: string };

type dialogInput = {
  stageId: number;
  classroomId: string;
  date: string;
  isToday: boolean;
  paragraph?: number;
  dictation?: number;
  reading?: number;
  lastWord: string;
};

@Component({
  selector: 'app-diary-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    ReactiveFormsModule,
    MatInputModule,
    MatDialogActions,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
  ],
  templateUrl: './diary-dialog.component.html',
  styleUrl: './diary-dialog.component.scss',
})
export class DiaryDialogComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<DiaryDialogComponent>);
  readonly data = inject<dialogInput>(MAT_DIALOG_DATA);

  protected getDayOfWeek = getDayOfWeek;

  readonly NO_SHOW = 'no show';

  protected apiService = inject(ApiService);
  protected authService = inject(AuthService);
  protected diaryService = inject(DiaryService);
  protected turmaService = inject(TurmaService);

  private snackBar = inject(MatSnackBar)
  
  studentsList: IAluno[] = this.turmaService.students;

  diaryForm: FormGroup;
  constructor(private fb: FormBuilder) {
    const numberPattern = Validators.pattern('^[0-9]*$');

    this.diaryForm = this.fb.group({
      paragraph: [
        this.data.paragraph ?? '',
        [Validators.required, numberPattern],
      ],
      lastWord: [this.data.lastWord ?? '', [Validators.required]],
      dictation: [this.data.dictation ?? '', [numberPattern]],
      reading: [this.data.reading ?? '', [numberPattern]],
      stageId: [this.data.stageId, [Validators.required]],
      students: [[], [Validators.required]]
    });

  }

  formDisabled(): boolean{
    return this.authService.hasRole(ECargo.Professor) && !this.data.isToday;
  }

  ngOnInit(): void {

    if(this.isFutureDate(this.data.date))
      return

    this.apiService.get<{present:boolean, studentId: string}[]>(`/attendance/${this.data.classroomId}/${this.data.date}`)
    .subscribe(attendances=>{

      let studentsIds = attendances.filter(a=> a.present).map(a=>a.studentId)

      if (studentsIds.length == 0 && attendances.length > 0)
        studentsIds = [this.NO_SHOW];

      this.diaryForm.get('students')?.setValue(studentsIds)
    })
  }

  isFutureDate(dateString: string): boolean {
    const inputDate = new Date(dateString);
    const today = new Date();
    
    // Reset the time portion of the current date to midnight for accurate comparison
    today.setHours(0, 0, 0, 0);

    return inputDate > today;
  }


  estagios: option[] = Array.from({ length: 12 }, (_, i) => {
    return { id: i + 1, name: `Stage ${i + 1}` };
  });

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close();

    let studentsIds: Array<any> = this.diaryForm.get('students')?.value

    if(studentsIds.length == 1 && studentsIds[0] == this.NO_SHOW)
      studentsIds = [];

    this.diaryService.put({
        stage_id: this.diaryForm.get('stageId')?.value,
        paragraph: this.diaryForm.get('paragraph')?.value,
        last_word: this.diaryForm.get('lastWord')?.value,
        dictation: this.diaryForm.get('dictation')?.value,
        reading: this.diaryForm.get('reading')?.value,
        students: studentsIds,
        classroom_id: this.data.classroomId,
        date: this.data.date,
      })
      .subscribe({
        next: () => {
          this.snackBar.open("Lançamento salvo com sucesso!", "Ok")
          this.diaryService.load(this.data.classroomId);
        },
        error:() => {
          this.snackBar.open("Houve um erro ao salvar o laçamento", "Ok")
        }
      });
  }

  onSelectionChange() {
    const selectedValues = this.diaryForm.get('students')?.value;

    if (selectedValues.includes(this.NO_SHOW) && selectedValues.length > 1)
      this.diaryForm.get('students')?.setValue([this.NO_SHOW]);
  }
}