import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { range } from '../../utils/range.util';
import { EGrade } from '../../enums/e-grade.enum';
import { getEnumValue } from '../../utils/get-enum-value.util';

@Component({
  selector: 'app-stars',
  standalone: true,
  imports: [
    MatIconModule
  ],
  templateUrl: './stars.component.html',
  styleUrl: './stars.component.scss'
})
export class StarsComponent implements OnInit {

  protected value: number = 0;
  @Input() grade: keyof typeof EGrade = "C";
  
  range: number[] = [];

  ngOnInit(): void {
    this.value = EGrade[this.grade];
    this.range = range(1, Object.keys(EGrade).length / 2)
  }

  changeGrade(value: number){
    this.value = value
    this.grade = getEnumValue(EGrade, value);
  }

}
