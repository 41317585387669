import {
  AfterViewInit,
  Component,
  inject,
  ViewChild,
} from '@angular/core';
import { LoadingComponent } from '../../components/loading/loading.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { LiveBoardComponent } from '../../components/live-board/live-board.component';
import { StatisticComponent } from '../../components/statistic/statistic.component';
import { ITurma } from '../../models/turma.model';
import { ApiService } from '../../services/api.service';
import { range } from '../../utils/range.util';
import { enumToString } from '../../utils/enum-to-string.util';
import {
  ETurmaStatus,
  ETurmaStatusColor,
  getTurmaStatusColor,
} from '../../enums/e-turma-status.enum';
import { MatTableModule } from '@angular/material/table';
import { ChipComponent } from '../../components/chip/chip.component';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { TurmaService } from '../../services/turma.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { TurmaDialogComponent } from '../../components/turma-dialog/turma-dialog.component';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { switchMap } from 'rxjs';
import { NgClass } from '@angular/common';
import { LogomarkComponent } from '../../components/logomark/logomark.component';
import { AuthService } from '../../services/auth.service';
import { ECargo } from '../../enums/e-cargo.enum';
import { TimesheetDialogComponent } from '../../components/timesheet-dialog/timesheet-dialog.component';
import { IDiario } from '../../models/diario.model';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    LoadingComponent,
    MatIconModule,
    MatTableModule,
    ChipComponent,
    RouterModule,
    LiveBoardComponent,
    StatisticComponent,
    NotFoundComponent,
    MatMenuModule,
    MatButtonModule,
    MatPaginatorModule,
    NgClass,
    LogomarkComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements AfterViewInit {
  displayedColumns: string[] = [
    'nome',
    'unidade',
    'horario',
    'estagio',
    'formato',
    'status',
    'actions',
  ];

  booksRange: number[] = [];

  protected enumToString = enumToString;
  protected eTurmaStatus = ETurmaStatus;
  protected eTurmaStatusColor = ETurmaStatusColor;
  protected getTurmaStatusColor = getTurmaStatusColor;
  readonly dialog = inject(MatDialog);
  protected authService = inject(AuthService);

  timesheet?: {
    currentMonth: string;
    totalClasses: number;
    diaryList: IDiario[];
  };

  constructor(protected apiService: ApiService) {
    this.booksRange = range(1, 12);
  }

  protected turmaService = inject(TurmaService);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngAfterViewInit(): void {
    this.paginator.page
      .pipe(switchMap(() => this.loadData()))
      .subscribe(this.turmaService.onLoad.bind(this.turmaService));

    if (this.turmaService.turmas.data.length === 0)
      this.loadData().subscribe(
        this.turmaService.onLoad.bind(this.turmaService)
      );

    if (this.authService.currentUser.roles?.includes(ECargo.Professor))
      this.apiService
        .get<typeof this.timesheet>('diario/timesheet')
        .subscribe((timesheet) => {
          this.timesheet = timesheet;
        });
  }

  loadData() {
    const pageIndex = this.paginator.pageIndex;
    const pageSize = this.paginator.pageSize;
    return this.turmaService.getAll(pageIndex, pageSize);
  }

  edit(turma: ITurma): void {
    this.dialog.open(TurmaDialogComponent, {
      data: turma,
    });
  }

  openTimesheetDialog(): void {
    this.dialog.open(TimesheetDialogComponent, {
      data: this.timesheet?.diaryList,
    });
  }

  isTeacher(): boolean {
    return this.authService.hasRole(ECargo.Professor);
  }

  canEdit(): boolean {
    const roles = this.authService.currentUser.roles;

    return (
      roles !== undefined &&
      (roles.includes(ECargo.Adm) || roles.includes(ECargo.Coordenador))
    );
  }
}