import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ITurma } from '../models/turma.model';
import { ETurmaStatus } from '../enums/e-turma-status.enum';
import { IPaginatedResult } from '../models/paginatedResult.model';
import { MatTableDataSource } from '@angular/material/table';
import { finalize } from 'rxjs';
import { IAluno } from '../models/aluno.model';

@Injectable({
  providedIn: 'root',
})
export class TurmaService {
  private apiService = inject(ApiService);
  public turmas = new MatTableDataSource<ITurma>([]);

  public students: IAluno[] = [];

  _isLoading: boolean = false;
  totalItems: number = 0;
  pageIndex: number = 0;

  get isLoading() {
    return this._isLoading;
  }

  put(classroom: Partial<ITurma>) {
    this._isLoading = true;
    this.apiService.post('classrooms/', classroom).subscribe({
      next: () => {
        this._isLoading = false;
        setTimeout(() => {
          this.getAll().subscribe(this.onLoad.bind(this));
        }, 0);
      },
      error: () => {
        this._isLoading = false;
      },
    });
  }

  onLoad(data: { items: ITurma[]; total: number }) {
    this.turmas.data = data.items;
    this.totalItems = data.total;
  }

  getAll(pageIndex: number = 0, pageSize: number = 8) {

    this.pageIndex = pageIndex;
    
    this._isLoading = true;
    return this.apiService
      .getData<IPaginatedResult<ITurma>>('classrooms/', {
        pageIndex,
        pageSize,
      })
      .pipe(
        finalize(() => {
          this._isLoading = false;
        })
      );
  }
}
