import { Component, input, InputSignal, OnInit } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { IAluno } from '../../models/aluno.model';
import { StarsComponent } from '../stars/stars.component';
import { MatIconModule } from '@angular/material/icon';
import { randomInt } from '../../utils/random-int.util';

@Component({
  selector: 'app-students-table',
  standalone: true,
  imports: [MatTableModule, StarsComponent, MatIconModule],
  templateUrl: './students-table.component.html',
  styleUrl: './students-table.component.scss',
})
export class StudentsTableComponent implements OnInit {
  students = input.required<IAluno[]>();
  attendance = input.required<{ [key: string]: number }>();

  getPresencaColor(presenca: number) {
    return 'var(--' + (presenca > 50 ? 'green' : 'red') + ')';
  }

  ngOnInit(): void {
    this.students().forEach(s=>{
      s.avatarId = randomInt(1, 16);
    })
  }

  displayedColumns: string[] = [
    'nome',
    'presenca',
    'writing',
    'speaking',
    'reading',
  ];
}
