import { Component, inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ApiService } from '../../services/api.service';
import { ECargo } from '../../enums/e-cargo.enum';
import { ColaboradorService } from '../../services/colaborador.service';
import { IColaborador } from '../../models/colaborador.model';
import { MatIconModule } from '@angular/material/icon';

type option = Record<'id' | 'name', string>;

@Component({
  selector: 'app-colaborador-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule
  ],
  templateUrl: './colaborador-dialog.component.html',
  styleUrl: './colaborador-dialog.component.scss',
})
export class ColaboradorDialogComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<ColaboradorDialogComponent>);
  readonly colaborador? = inject<IColaborador>(MAT_DIALOG_DATA);

  apiService = inject(ApiService);
  colaboradorService = inject(ColaboradorService);

  rolesList: { name: string; id: ECargo }[] = [
    { name: 'Professor(a)', id: ECargo.Professor },
    { name: 'Coodenador(a)', id: ECargo.Coordenador },
    { name: 'Adm', id: ECargo.Adm },
  ];

  onNoClick(): void {
    this.dialogRef.close();
  }

  unidades: option[] = [];
  ngOnInit(): void {
    this.apiService.get<option[]>("classrooms/school-units").subscribe(unidades => {
      this.unidades = unidades;
      this.form.get('unit')?.setValue(this.colaborador?.unitId)
    })
  }

  form: FormGroup;
  constructor(private fb: FormBuilder){
    this.form = this.fb.group({
      name: [this.colaborador?.name ?? '', [Validators.required]],
      unit: ['', [Validators.required]],
      roles: [this.colaborador?.roles ?? [], [Validators.required]],
    });
  }

  onSubmit() {
    if (this.colaborador) this.updateUser(this.colaborador);
    else this.addUser();
  }

  updateUser(colaborador: IColaborador) {
    colaborador.name = this.form.get('name')?.value
    colaborador.roles = this.form.get('roles')?.value
    colaborador.unitId = this.form.get('unit')?.value

    colaborador.unit = this.unidades.filter(u=>u.id == colaborador.unitId)[0].name;

    this.dialogRef.close();
    this.colaboradorService.update(colaborador).subscribe();
  }

  addUser() {
    this.dialogRef.close();
    this.colaboradorService
      .signUp(this.form.value)
      .subscribe({
        next: () => {
          setTimeout(() => {
            this.colaboradorService.getAll();
          }, 0);
        },
        error: () => {},
      });
  }
}
