export function enumToString(
  enumerable: any,
  enumValue: number
): string | undefined {
  // Loop through enum keys
  for (const key in enumerable) {
    // alert(key);
    if (enumerable[key as keyof typeof enumerable] === enumValue) {
        const stringValue = key.replaceAll("_", " ").toLocaleLowerCase();
        return stringValue.charAt(0).toUpperCase() + stringValue.slice(1);
    }
  }
  return undefined; // Handle case where enum value is not found
}