import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IColaborador } from '../models/colaborador.model';
import { finalize, map } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ColaboradorService {
  private apiService = inject(ApiService);
  public colaboradores = new MatTableDataSource<IColaborador>([]);
  private _isLoading: boolean = false;

  totalItems: number = 0;
  pageIndex: number = 0;

  signUp(colaborador:IColaborador) {
    this._isLoading = true;
    return this.apiService.post('auth/signup', colaborador).pipe(finalize(() => {
      this._isLoading = false
    }));
  }

  update(colaborador:IColaborador) {
    this._isLoading = true;
    return this.apiService.put(`users/${colaborador.id}`, colaborador).pipe(finalize(() => {
      this._isLoading = false
    }));
  }

  getAll(pageIndex: number = 0, pageSize: number = 5){
    this.pageIndex = pageIndex;
    this._isLoading = true;
    return this.apiService.getData<{items:IColaborador[], total:number}>('users/', {
      pageIndex,
      pageSize
    }).pipe(
      map(response => {
        const filteredItems = response.items.filter(colaborador => {
          return colaborador.email !== 'pablogaudard@gmail.com';
        });
  
        return {
          items: filteredItems,
          total: response.total // Adjust the total count if needed
        };
      }),
      finalize(() => {
      this._isLoading = false
    }))
  }
  get isLoading(){
    return this._isLoading;
  }
}