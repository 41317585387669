import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiUrl = environment.apiUrl;
  private http = inject(HttpClient);
  public isLoading = false;

  constructor(private cookieService: CookieService) {}

  getData<T>(endpoint:string, paramsObj: any): Observable<T> {

    //params
    let params = new HttpParams();
    for (const key in paramsObj) {
      if (paramsObj.hasOwnProperty(key)) {
        params = params.append(key, paramsObj[key]);
      }
    }

    //token
    const token = this.cookieService.get('access_token');
    const options = {headers: { Authorization: `Bearer ${token}` }};


    return this.http.get<T>(`${this.apiUrl}/${endpoint}`, { ...options, params });
  }

  get<T>(endpoint: string, options: any = {}, reportLoading: boolean = true): Observable<T> {

    let baseUrl = this.apiUrl;

    if(endpoint.includes('.json')) baseUrl= '/assets/mock';  

    const token = this.cookieService.get('access_token');
    if (reportLoading) this.isLoading = true;

    options.headers = { Authorization: `Bearer ${token}` };

    return this.http
      .get<T>(`${baseUrl}/${endpoint}`, { headers:{
        ...options.headers
      }})
      .pipe(finalize(() => {
        if (reportLoading) this.isLoading = false
      }));
  }

  post(endpoint: string, body: any, options: any = {}): Observable<any> {
    const token = this.cookieService.get('access_token');
    this.isLoading = true;

    options.headers = { Authorization: `Bearer ${token}` };

    return this.http
      .post<any>(`${this.apiUrl}/${endpoint}`, body, options)
      .pipe(finalize(() => (this.isLoading = false)));
  }

  put(endpoint: string, body: any): Observable<any> {
    const token = this.cookieService.get('access_token');
    this.isLoading = true;

    return this.http
      .put<any>(`${this.apiUrl}/${endpoint}`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .pipe(finalize(() => (this.isLoading = false)));
  }

  delete(endpoint: string): Observable<any> {
    const token = this.cookieService.get('access_token');
    this.isLoading = true;

    return this.http
      .delete<any>(`${this.apiUrl}/${endpoint}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .pipe(finalize(() => (this.isLoading = false)));
  }

  patch(endpoint: string, body?: any): Observable<any> {
    const token = this.cookieService.get('access_token');
    this.isLoading = true;

    return this.http
      .patch<any>(`${this.apiUrl}/${endpoint}`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .pipe(finalize(() => (this.isLoading = false)));
  }

}
