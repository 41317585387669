@if(turmaService.turmas.data.length === 0) {
<div class="h-full flex flex-col justify-center items-center gap-5 text-light">
  <app-logomark [theme]="'dark'" class="md:hidden"></app-logomark>
  <app-loading></app-loading>
</div>
}
@else {

@if(isTeacher()){
<div class="mb-4 gap-4 flex flex-row">

  <div class="card max-md:w-full p-4 w-1/3 bg-right-bottom bg-contain bg-no-repeat
        flex flex-row bg-[url(/assets/triangle-light.png)]">
    <div class="w-2/3 flex flex-col gap-2">

      <h6>{{timesheet?.currentMonth}}</h6>

      <p class="text-sm text-light">
        Horas Trabalhadas
      </p>

      <h6 style="font-size: 1rem; color: var(--green)">
        <mat-icon class="align-middle">trending_up</mat-icon>
        {{timesheet?.totalClasses}} horas
      </h6>

      <div>
        <button mat-button class="button-squared bg--green" (click)="openTimesheetDialog()">
          <mat-icon>search</mat-icon>
          detalhes
        </button>
      </div>

    </div>

    <div class="w-1/3 flex items-end justify-center">
      <img src="/assets/livro-didatico.png" alt="Book cover" class="w-full">
    </div>
  </div>

  <div class="card max-md:hidden w-2/3 p-4 flex flex-col justify-between">
    <h6 class="!mb-0">Comunicados</h6>

    <app-live-board class="h-full">
      <p class="text-sm !mt-3">
        <span class="font-medium">A turma London está em dia</span> 🥳
        <span class="text-light">com o plano</span>
      </p>
      <p class="text-sm !mt-3">
        <span class="font-medium">Lançar aula do dia 25</span> 🚨
        <span class="text-light">na turma Glasgow</span>
      </p>
    </app-live-board>

    <div class="flex w-full justify-between">
      <app-statistic color="yellow" label="Horas pendentes" icon="schedule">3 horas</app-statistic>
      <!-- <app-statistic label="Horas pendentes" icon="schedule">3h</app-statistic> -->
    </div>

  </div>

</div>
}
<div class="w-full relative">
  <div
    class="h-full w-full justify-center items-center absolute top-0 left-0 z-10 bg-black bg-opacity-5 rounded-default"
    [ngClass]="{'hidden': !turmaService.isLoading, 'flex': turmaService.isLoading}">
    <app-loading [shape]="'circle'"></app-loading>
  </div>

  <table mat-table [dataSource]="turmaService.turmas" class="table">

    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef>
        <mat-icon>school</mat-icon>
        <span class="max-md:hidden">Turma</span>
        <span class="md:hidden">Turmas</span>
      </th>
      <td mat-cell *matCellDef="let turma" [routerLink]="['/turma', turma.id]"> {{turma.name}} </td>
    </ng-container>

    <ng-container matColumnDef="unidade">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
        <mat-icon>place_on</mat-icon>
        Unidade
      </th>
      <td mat-cell *matCellDef="let turma" class="max-md:hidden" [routerLink]="['/turma', turma.id]"> {{turma.unit}}
      </td>
    </ng-container>

    <ng-container matColumnDef="horario">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
        <mat-icon>schedule</mat-icon>
        Horario
      </th>
      <td mat-cell *matCellDef="let turma" class="max-md:hidden" [routerLink]="['/turma', turma.id]"> {{turma.timeslot}}
      </td>
    </ng-container>

    <ng-container matColumnDef="formato">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden"> Formato </th>
      <td mat-cell *matCellDef="let turma" class="max-md:hidden" [routerLink]="['/turma', turma.id]"> {{turma.format}}
      </td>
    </ng-container>

    <ng-container matColumnDef="estagio">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
        <mat-icon>menu_book</mat-icon>
        Estágio
      </th>
      <td mat-cell *matCellDef="let turma" class="max-md:hidden" [routerLink]="['/turma', turma.id]"> {{turma.stage}}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden"> Status </th>
      <td mat-cell *matCellDef="let turma" class="max-md:hidden" [routerLink]="['/turma', turma.id]">
        <chip [color]="getTurmaStatusColor(turma.statusId)">
          {{enumToString(eTurmaStatus, turma.statusId)}}
        </chip>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef [ngClass]="{'hidden': !canEdit()}"></th>
      <td mat-cell *matCellDef="let turma" [ngClass]="{'hidden': !canEdit()}">

        <button mat-icon-button class="!overflow-hidden !rounded-full float-right" [matMenuTriggerFor]="options">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #options="matMenu">
          <button mat-menu-item (click)="edit(turma)">
            <mat-icon>edit</mat-icon>
            <span class="font-normal text-light">Editar</span>
          </button>
        </mat-menu>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let turma; columns: displayedColumns;"></tr>
  </table>
</div>
}

<div [hidden]="turmaService.turmas.data.length === 0 || isTeacher()" class="mt-5">
  <mat-paginator [pageSize]="isTeacher() ? 100 : 8" [hidePageSize]="true" [pageIndex]="turmaService.pageIndex"
    [length]="turmaService.totalItems" [showFirstLastButtons]="true">
  </mat-paginator>
</div>