@if(turma){
<h2 mat-dialog-title>Editar Turma</h2>
} @else {
<h2 mat-dialog-title>Nova Turma</h2>
}
<mat-dialog-content>
    <form [formGroup]="classroomForm" class="flex flex-col">

        <mat-form-field>
            <mat-label>Nome</mat-label>
            <input matInput formControlName="name" />
        </mat-form-field>

        <div class="flex md:flex-row md:gap-5 max-md:flex-col">
            <mat-form-field>
                <mat-label>Unidade</mat-label>
                <mat-select formControlName="unitId">
                    @for (unidade of unidades; track unidade) {
                    <mat-option [value]="unidade.id">{{unidade.name}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Estágio</mat-label>
                <mat-select formControlName="stageId">
                    @for (estagio of estagios; track estagio) {
                    <mat-option [value]="estagio.id">{{estagio.name}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>

        <div class="flex md:flex-row md:gap-5 max-md:flex-col">
            <mat-form-field>
                <mat-label>Formato</mat-label>
                <mat-select formControlName="formatId">
                    @for (estagio of formatos; track estagio) {
                    <mat-option [value]="estagio.id">{{estagio.name}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Horário</mat-label>
                <mat-select formControlName="timeslot">
                    @for (horario of horarios; track horario) {
                    <mat-option [value]="horario">{{horario}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-flat-button (click)="onSubmit()" [disabled]="classroomForm.invalid || apiService.isLoading">
        {{ turma ? 'Salvar' : 'Adicionar' }}
    </button>
</mat-dialog-actions>