@if (diaryService.isLoading) {
<div class="card w-full h-80 flex items-center justify-center">
  <app-loading [shape]="'circle'"></app-loading>
</div>
}
@else {

<div class="card">


  <table mat-table [dataSource]="diaryService.diaries" class="table !shadow-none">

    <ng-container matColumnDef="day">
      <th mat-header-cell *matHeaderCellDef class="text-nowrap">
        <mat-icon class="mat-18">calendar_month</mat-icon> September
      </th>
      <td mat-cell *matCellDef="let class" style="font-family: monospace;" class="text-light">

        <div class="flex flex-row gap-2 items-center">

          <div class="rounded-default py-1 px-2 items-center justify-center flex"
            [ngClass]="{'bg-blue-500 text-white shadow-md':class.isToday}">
            <span class="!font-bold mr-2">
              {{getDayOfWeek(class.date).split(' ')[0]}}
            </span>
            <span>
              {{getDayOfWeek(class.date).split(' ')[1]}}
            </span>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef> Class Type </th>
    <td mat-cell *matCellDef="let class"> {{class.type ? enumToString(eClassType, class.type) : '--'}} </td>
  </ng-container> -->

    <ng-container matColumnDef="paragraph">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden"> Paragraph </th>
      <td mat-cell *matCellDef="let class" class="max-md:hidden"> {{class.diary?.paragraph ?? '--' }} </td>
    </ng-container>

    <ng-container matColumnDef="lastWord">
      <th mat-header-cell *matHeaderCellDef> Last Word </th>
      <td mat-cell *matCellDef="let class"> {{class.diary?.lastWord ?? '--'}} </td>
    </ng-container>

    <ng-container matColumnDef="reading">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
        <mat-icon class="mat-18">menu_book</mat-icon>
        Reading
      </th>
      <td mat-cell *matCellDef="let class" class="max-md:hidden"> {{class.diary?.reading ?? '--'}} </td>
    </ng-container>

    <ng-container matColumnDef="dictation">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
        <mat-icon class="mat-18">edit</mat-icon>
        Dictation
      </th>
      <td mat-cell *matCellDef="let class" class="max-md:hidden"> {{class.diary?.dictation ?? '--'}} </td>
    </ng-container>

    <ng-container matColumnDef="teacher">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
        Teacher
      </th>
      <td mat-cell *matCellDef="let class" class="max-md:hidden"> {{class.diary?.teacherName}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="max-md:hidden">
        Status
      </th>
      <td mat-cell *matCellDef="let class" class="max-md:hidden">
        <chip [color]="getEnumValue(eClassStatusColor, class.status)">
          {{enumToString(eClassStatus, class.status)}}
        </chip>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let class; columns: displayedColumns;" (click)="openDialog(class)"></tr>
  </table>


  <div class="flex flex-row justify-end border-t border-gray-300 p-2 gap-2">

    
    <button mat-icon-button class="!overflow-hidden !rounded-full">
      <mat-icon>chevron_left</mat-icon>
    </button>
    
    
    <button mat-icon-button class="!overflow-hidden !rounded-full">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>

</div>
}