<!-- Desktop Toolbar -->
<div class="flex flex-row gap-5 py-3 items-center max-md:hidden bg-default">

  <!-- Left Side -->
  <div class="pl-3 md:basis-[var(--sidenav-width)] flex flex-row gap-4 items-center">
    <button mat-icon-button class="!overflow-hidden !rounded-full" (click)="snav().toggle()">
      <mat-icon>menu</mat-icon>
    </button>

    <a [routerLink]="['']">
      <app-logomark theme="dark"></app-logomark>
    </a>
  </div>

  <!-- Right Side -->
  <div class="flex flex-row justify-between pr-5 max-md:justify-end" id="right-side">

    <mat-form-field appearance="outline" class="small -mb-5 rounded">
      <mat-label>Pesquisar</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input matInput type="text" [disabled]="true">
    </mat-form-field>

    <div class="flex items-center gap-4">
      <button mat-icon-button [matMenuTriggerFor]="notifications" class="!overflow-hidden !rounded-full">
        <mat-icon>notifications_none</mat-icon>
      </button>
      <button [matMenuTriggerFor]="profile">
        <img src="/assets/people/person-5.jpeg" alt="Profile Picture" class="w-10 rounded-full">
      </button>
    </div>

  </div>
</div>

<!-- Mobile Toolbar -->
<div class="flex flex-row md:hidden justify-between px-5 py-4 bg-default">

  <div class="flex flex-row items-center gap-4">
    <button [matMenuTriggerFor]="profile">
      <img src="/assets/people/person-5.jpeg" alt="Profile Picture" class="w-10 rounded-full">
    </button>
    <h1 class="!text-xl text-nowrap">{{firstName()}}</h1>
  </div>

  <div class="flex flex-row items-center">
    <button mat-icon-button [matMenuTriggerFor]="notifications" class="!overflow-hidden !rounded-full">
      <mat-icon>notifications_none</mat-icon>
    </button>
    <button mat-icon-button class="!overflow-hidden !rounded-full" (click)="snav().toggle()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
</div>

<!-- Notifications -->
<mat-menu #notifications="matMenu">
  <button mat-menu-item>
    <mat-icon>info_outline</mat-icon>
    <span class="font-normal text-light">Você não tem notificações</span>
  </button>
</mat-menu>

<!-- Profile Shortcuts -->
<mat-menu #profile="matMenu">
  <button mat-menu-item>
    <mat-icon>person</mat-icon>
    <span>{{authService.currentUser.name}}</span>
  </button>

  <button mat-menu-item class="!text-red-500" (click)="authService.logout()">
    <mat-icon class="!text-red-500">logout</mat-icon>
    <span>Sair</span>
  </button>
</mat-menu>