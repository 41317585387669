import { Component, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from '../../services/auth.service';
import { LogomarkComponent } from '../logomark/logomark.component';
import { MatSidenav } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    LogomarkComponent,
    RouterModule
  ],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss'
})
export class ToolbarComponent {

  protected authService = inject(AuthService)
  snav= input.required<MatSidenav>()

  firstName(){
    return this.authService.currentUser.name?.split(' ')[0]
  }

}
