<table mat-table [dataSource]="students()" class="table border-0">

    <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef>
            <h6 class="text-left">
                Alunos
            </h6>
        </th>
        <td mat-cell *matCellDef="let student">
            <div class="flex gap-4 py-4">
                <div class="w-12">
                    <img [src]="'assets/people/person-'+student.avatarId+'.jpeg'" alt="Foto do aluno" class="w-full rounded-full">
                </div>
                <div style="width: calc(100% - 4rem);">
                    @let presenca = attendance()[student.id];
                    <p class="!mb-0 font-medium w-full overflow-hidden whitespace-nowrap text-ellipsis truncate ">{{student.name}}</p>
                    <p class="max-md:hidden !mb-0 text-light">{{student.age}} anos</p>
                    <p class="md:hidden">
                        <span class="text-light">
                            Presença:
                        </span>
                        <span [style.color]="getPresencaColor(presenca)" style="font-weight: 500;">
                            {{presenca}}%
                        </span>
                    </p>
                </div>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="presenca">
        <th mat-header-cell *matHeaderCellDef class="max-md:hidden">Presenca</th>
        <td mat-cell *matCellDef="let student" class="max-md:hidden">
            @let presenca = attendance()[student.id];
            <span [style.color]="getPresencaColor(presenca)" style="font-weight: 500;">
                {{presenca}}%
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="writing">
        <th mat-header-cell *matHeaderCellDef class="max-md:hidden">Writing</th>
        <td mat-cell *matCellDef="let student" class="max-md:hidden">
            <app-stars [grade]="student.writing"></app-stars>
        </td>
    </ng-container>

    <ng-container matColumnDef="speaking">
        <th mat-header-cell *matHeaderCellDef class="max-md:hidden">Speaking</th>
        <td mat-cell *matCellDef="let student" class="max-md:hidden">
            <app-stars [grade]="student.speaking"></app-stars>
        </td>
    </ng-container>

    <ng-container matColumnDef="reading">
        <th mat-header-cell *matHeaderCellDef class="max-md:hidden">Reading</th>
        <td mat-cell *matCellDef="let student" class="max-md:hidden">
            <app-stars [grade]="student.reading"></app-stars>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>