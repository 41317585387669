<div class="statistic" [ngClass]="color">
    <div class="icon">
      <div>
        <mat-icon>{{icon}}</mat-icon>
      </div>
    </div>
    <div>
      <p>{{label}}</p>
      <h6>
        <ng-content></ng-content>
      </h6>
    </div>
  </div>