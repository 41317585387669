<h2 mat-dialog-title>
@if(showExamLink){
  Prova Agendada!
}
@else {
  Agendar Prova {{data?.title}}
}
</h2>
<mat-dialog-content>
  @if(showExamLink){
  <div class="flex flex-col">

    <mat-form-field>
      <mat-label>Link da prova</mat-label>
      <input matInput type="text" [value]="examLink" readonly>

      <button mat-icon-button matSuffix (click)="copyText(examLink)">
        <mat-icon>content_copy</mat-icon>
      </button>

    </mat-form-field>

    <mat-form-field>
      <mat-label>Código de acesso</mat-label>
      <input matInput type="text" [value]="exam.accessToken" readonly>

      <button mat-icon-button matSuffix (click)="copyText(exam.accessToken||'')">
        <mat-icon>content_copy</mat-icon>
      </button>
    </mat-form-field>

  </div>
  }
  @else{ 
  <div class="grid grid-cols-2 max-md:grid-cols-1 gap-x-5 pt-5">
    <mat-form-field>
      <mat-label>Nome do Aluno</mat-label>
      <input matInput type="text" [(ngModel)]="exam.fullName" name="fullName" required list="students"
        (input)="getStudentEmail()" />
    </mat-form-field>

    <datalist id="students">
      <option *ngFor="let student of students">{{student.full_name}}</option>
    </datalist>

    <mat-form-field>
      <mat-label>E-mail</mat-label>
      <mat-icon matSuffix>mail</mat-icon>
      <input matInput type="email" [(ngModel)]="exam.email" name="email" required [readonly]="exam.studentId != undefined && exam.studentId != ''" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Data de Início</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="exam.startTime" required />
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Duração em minutos</mat-label>
      <mat-icon matSuffix>schedule</mat-icon>
      <input matInput type="number" [(ngModel)]="exam.duration" name="duration" required />
    </mat-form-field>
  </div>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancelar</button>
  @if(showExamLink){
  <button mat-flat-button (click)="copyExamInfo()">
    <mat-icon>content_copy</mat-icon>
    Copiar link e código
  </button>
  }
  @else {
  <button mat-flat-button (click)="submitForm()" [disabled]="sumbitButtonDisabled">
    <mat-icon>calendar_month</mat-icon>
    Agendar
  </button>
  }
</mat-dialog-actions>