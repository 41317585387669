<h2 mat-dialog-title>
    Aula - {{getDayOfWeek(data.date, true)}}
</h2>
<mat-dialog-content>
    <form [formGroup]="diaryForm" class="flex flex-col">

        <div class="grid grid-cols-2 gap-x-4">

            <mat-form-field class="col-span-2">
                <mat-label>Lista de Presença</mat-label>
                <mat-select formControlName="students" multiple  (selectionChange)="onSelectionChange()">
                    <mat-option [value]="NO_SHOW" [disabled]="formDisabled()">No show</mat-option>
                    @for (student of studentsList; track student) {
                    <mat-option [value]="student.id" [disabled]="formDisabled() || diaryForm.get('students')?.value.includes(NO_SHOW)">{{student.name}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-span-2">
                <mat-label>Last Word</mat-label>
                <mat-icon matSuffix>bookmark</mat-icon>
                <input matInput formControlName="lastWord" [readonly]="formDisabled()"/>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Paragraph</mat-label>
                <mat-icon matSuffix>menu_book</mat-icon>
                <input matInput formControlName="paragraph" [readonly]="formDisabled()"/>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Dictation</mat-label>
                <mat-icon matSuffix>edit</mat-icon>
                <input matInput formControlName="dictation" [readonly]="formDisabled()"/>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Reading</mat-label>
                <mat-icon matSuffix>local_library</mat-icon>
                <input matInput formControlName="reading" [readonly]="formDisabled()"/>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Estágio</mat-label>
                <mat-icon matSuffix>school</mat-icon>
                <mat-select formControlName="stageId">
                    @for (estagio of estagios; track estagio) {
                    <mat-option [value]="estagio.id" [disabled]="formDisabled()">{{estagio.name}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

        </div>

    </form>
</mat-dialog-content>
<mat-dialog-actions>
    @if(formDisabled()){
        <button mat-button (click)="onNoClick()">Fechar</button>
    }@else {
        <button mat-button (click)="onNoClick()">Cancelar</button>
        <button mat-flat-button (click)="onSubmit()" [disabled]="diaryForm.invalid || apiService.isLoading">
            Salvar
        </button>
    }
</mat-dialog-actions>