import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkStatusService {
  private onlineStatus = new BehaviorSubject<boolean>(navigator.onLine);
  private status: boolean = true;

  constructor() {
    window.addEventListener('online', () => this.updateOnlineStatus(true));
    window.addEventListener('offline', () => this.updateOnlineStatus(false));

    this._isOnline.subscribe((status) => {
      this.status = status;
    });
  }

  private updateOnlineStatus(isOnline: boolean) {
    this.onlineStatus.next(isOnline);
  }

  private get _isOnline(): Observable<boolean> {
    return this.onlineStatus.asObservable();
  }

  get isOnline(): boolean {
    return this.status;
  }
}
