
<div class="flex items-baseline gap-2 " 
    [ngClass]="{
        'grayscale brightness-0': theme() != 'colored',
        'invert': theme() == 'light',
        'invert-1': theme() == 'dark'
    }">

    <img src="/assets/logo.png" alt="Logo USK" style="width: 30px;">
    <h6 class="!mb-0" style="font-family:logomark; color: var(--primary); font-size: 2.2rem;">
        USK
    </h6>
</div>