import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'app-logomark',
  standalone: true,
  imports: [NgClass],
  templateUrl: './logomark.component.html',
  styleUrl: './logomark.component.scss'
})
export class LogomarkComponent {


  theme = input<'light'|'colored'|'dark'>('colored')

}
