import {
  AfterViewInit,
  Component,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { IColaborador } from '../../models/colaborador.model';
import { LoadingComponent } from '../../components/loading/loading.component';
import { ChipComponent } from '../../components/chip/chip.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ColaboradorService } from '../../services/colaborador.service';
import { ECargo } from '../../enums/e-cargo.enum';
import { MatDialog } from '@angular/material/dialog';
import { ColaboradorDialogComponent } from '../../components/colaborador-dialog/colaborador-dialog.component';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { switchMap } from 'rxjs';
import { NgClass } from '@angular/common';
import { T } from '@angular/cdk/keycodes';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LogomarkComponent } from '../../components/logomark/logomark.component';
import { randomInt } from '../../utils/random-int.util';

@Component({
  selector: 'app-colaboradores',
  standalone: true,
  imports: [
    MatTableModule,
    LoadingComponent,
    ChipComponent,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatPaginatorModule,
    NgClass,
    LogomarkComponent
  ],
  templateUrl: './colaboradores.component.html',
  styleUrl: './colaboradores.component.scss',
})
export class ColaboradoresComponent implements AfterViewInit {
  readonly dialog = inject(MatDialog);

  displayedColumns: string[] = ['name', 'email', 'roles', 'unit', 'actions'];
  eCargo = ECargo;

  protected colaboradorService = inject(ColaboradorService);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngAfterViewInit(): void {
    this.paginator.page
      .pipe(switchMap(() => this.loadData()))
      .subscribe(this.onLoad.bind(this));

    if(this.colaboradorService.colaboradores.data.length === 0)
      this.loadData().subscribe(this.onLoad.bind(this));
  }

  onLoad(data: { items: IColaborador[]; total: number }) {
    this.colaboradorService.colaboradores.data = data.items.map(colaborador=>{
      colaborador.avatarId= randomInt(1,16);
      return colaborador;
    });
    this.colaboradorService.totalItems = data.total;
  }

  loadData() {
    const pageIndex = this.paginator.pageIndex;
    const pageSize = this.paginator.pageSize;
    return this.colaboradorService.getAll(pageIndex, pageSize);
  }

  edit(colaborador: IColaborador) {
    this.dialog.open(ColaboradorDialogComponent, {
      data: colaborador,
    });
  }
}